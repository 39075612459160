import {
    LeveledLogMethod,
    Logger,
    LoggerMethods,
    logMethods,
} from '../../../shared';
export const createRemixClientLogger = (): Logger => {
    // The logger is instantiated outside the remix context
    // so it's just a simple fetch call
    const sendRequest = (
        method: keyof LoggerMethods,
        // This is just used as a reference to satisify the return type
        loggerRef: Logger
    ): LeveledLogMethod => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (message: string, ...meta: any[]): Logger => {
            const formData = new FormData();
            formData.append('method', method);
            formData.append('message', message);
            formData.append(
                'meta',
                JSON.stringify({
                    ...meta
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .map((value: any) => {
                            if (value instanceof Error) {
                                const { message, stack } = value;
                                return {
                                    error: { message, stack },
                                };
                            }
                            return value;
                        })
                        .reduce((prev, curr) => {
                            return { ...prev, ...curr };
                        }, {}),
                    component: 'remix-client',
                })
            );

            sendLog(formData);
            return loggerRef;
        };
    };

    return logMethods.reduce((acc, method) => {
        acc[method] = sendRequest(method, acc);
        return acc;
    }, {} as LoggerMethods);
};

export const createMockLogger = (): Logger => {
    // The logger is instantiated outside the remix context
    // so it's just a simple fetch call
    const sendRequest = (
        method: keyof LoggerMethods,
        // This is just used as a reference to satisify the return type
        loggerRef: Logger
    ): LeveledLogMethod => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (message: string, ...meta: any[]): Logger => {
            console.log('Mock logger', method, message, meta);
            return loggerRef;
        };
    };

    return logMethods.reduce((acc, method) => {
        acc[method] = sendRequest(method, acc);
        return acc;
    }, {} as LoggerMethods);
};

const sendLog = async (body: FormData) => {
    try {
        await fetch('/remix-api/log', {
            method: 'POST',
            body: body,
        });
    } catch (_) {
        return;
    }
};
