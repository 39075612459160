import { LoggerMethods } from '../types';

// Must contain all values listed in {@link LoggerMethods}
export enum LogLevel {
    emerg,
    alert,
    crit,
    error,
    warning,
    notice,
    info,
    debug,
}

export const logMethods = Object.keys(LogLevel) as Array<keyof LoggerMethods>;
