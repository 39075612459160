import { RemixBrowser } from '@remix-run/react';
import { RendererProvider } from '@archipro-design/aria';
import { hydrateRoot } from 'react-dom/client';
import { createFelaRendererFactory } from '../react-northstar-fela-renderer/src/createFelaRenderer';
import { startTransition } from 'react';
import {
    createRemixClientLogger,
    LoggerProvider,
} from '@archipro-website/logger/client';
import { RenderContextProvider } from './render-context';
import { DebugContextProvider } from './modules/error/context/DebugContext';

// https://github.com/facebook/react/issues/24430#issuecomment-1156537554
document.querySelectorAll('html > script').forEach((s) => {
    s?.parentNode?.removeChild(s);
});

const { create: createFelaRenderer } = createFelaRendererFactory();
const renderer = createFelaRenderer();

const hydrate = () => {
    startTransition(() => {
        const logger = createRemixClientLogger();
        /**
         * we hydrate the head first. The head-portal div is just a placeholder to latch on to
         * so that we can use createPortal to render the head.
         */
        hydrateRoot(
            document.getElementById('head-portal')!,
            <RenderContextProvider renderType="browser-head">
                <LoggerProvider logger={logger}>
                    <RemixBrowser />
                </LoggerProvider>
            </RenderContextProvider>
        );
        hydrateRoot(
            document.getElementById('root')!,
            <RenderContextProvider renderType="browser-body">
                <LoggerProvider logger={logger}>
                    <RendererProvider value={() => renderer}>
                        <DebugContextProvider>
                            <RemixBrowser />
                        </DebugContextProvider>
                    </RendererProvider>
                </LoggerProvider>
            </RenderContextProvider>,
            {
                onRecoverableError: (err) => {
                    /**
                     * This is fela specific logic.
                     * We catch the hydration fail that forces a full client-side mount,
                     * and re-run the full render of styles.
                     *
                     */
                    if (err instanceof Error) {
                        if (
                            err.message.includes(
                                import.meta.env.PROD
                                    ? // https://reactjs.org/docs/error-decoder.html?invariant=423
                                      'Minified React error #423'
                                    : 'the entire root will switch to client rendering'
                            )
                        ) {
                            renderer.reset();
                        }
                    }
                },
            }
        );
    });
};

if (window.requestIdleCallback) {
    window.requestIdleCallback(hydrate);
} else {
    // Safari doesn't support requestIdleCallback
    // https://caniuse.com/requestidlecallback
    window.setTimeout(hydrate, 1);
}
